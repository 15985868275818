<template>
    <div class="about-main">
        <top-nav></top-nav>
        <header-guide></header-guide>
        <bread-crumb></bread-crumb>
        <div
            class="sp-banner"
            :style="{backgroundImage:'url('+$img.about_banner+')'}"
        >
            <div class="container">
                <div class="sp-banner-txt">关于我们</div>
            </div>
        </div>
        <div class="main">
            <div class="container">
                <aside class="aside-panel">
                    <ul class="col-items">
                        <li
                            :class="{'active':item.url===web_url}"
                            class="item"
                            v-for="(item,index) in cloumn"
                            :key="index"
                            @click="toggle(index)"
                        >
                            <a :href="item.url">{{item.type}}</a>
                        </li>
                    </ul>
                </aside>

                <div class="contarea-panel">
                    <div class="contarea">
                        <div class="about-txt-cont">
                            <el-form
                                :model="contactForm"
                                label-width="100px"
                                ref="contactForm"
                                :rules="rules"
                            >
                                <el-form-item
                                    label="联系人姓名"
                                    prop="name"
                                >
                                    <el-input
                                        type="text"
                                        v-model="contactForm.name"
                                        placeholder="请填写"
                                        style="width:50%"
                                    ></el-input>
                                </el-form-item>

                                <el-form-item
                                    label="联系人电话"
                                    prop="phone"
                                >
                                    <el-input
                                        type="text"
                                        v-model="contactForm.phone"
                                        placeholder="请填写"
                                        style="width:50%"
                                    ></el-input>
                                </el-form-item>

                                <el-form-item
                                    label="联系人邮箱"
                                    prop="email"
                                >
                                    <el-input
                                        type="email"
                                        v-model="contactForm.email"
                                        placeholder="请填写"
                                        style="width:50%"
                                    ></el-input>
                                </el-form-item>

                                <el-form-item
                                    label="留言内容"
                                    prop="content"
                                >
                                    <el-input
                                        type="textarea"
                                        v-model="contactForm.content"
                                        placeholder="请填写"
                                        rows="10"
                                    ></el-input>
                                </el-form-item>

                                <el-form-item style="margin-top:20px;">
                                    <el-button
                                        type="primary"
                                        @click="submitForm()"
                                    >发送</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
                <div class="sidebar-panel">
                    <sidebar-panel></sidebar-panel>
                </div>
            </div>
        </div>

        <global-footer></global-footer>
    </div>
</template>

<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import SidebarPanel from "@/components/common/SidebarPanel.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
export default {
    name: "contact",
    metaInfo() {
        return {
            title: "联系我们 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
                }, {
                    name: "description",
                    content: "请填写您的联系信息和留言内容，我们会在第一时间查看并给您回复。"
                }
            ]
        }
    },
    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        BreadCrumb, //面包屑导航
        SidebarPanel, //右边公共模块
        GlobalFooter //公共底部
    },
    data() {
        return {
            currentIndex: 0,
            web_url: this.$route.path,
            cloumn: [
                {
                    type: "网站介绍",
                    url: "/about/index"
                },
                {
                    type: "联系我们",
                    url: "/contact/index"
                }
            ],
            contactForm: {
                name: "",
                phone: "",
                email: "",
                content: ""
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入姓名",
                        trigger: "blur"
                    }
                ],
                phone: [
                    {
                        required: true,
                        message: "手机号不能为空"
                    },
                    {
                        validator: function (rule, value, callback) {
                            if (/^1[345789]\d{9}$/.test(value) == false) {
                                callback(new Error("请输入正确的手机号"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur"
                    }
                ],
                email: [
                    {
                        required: true,
                        message: "邮箱不能为空"
                    },
                    {
                        pattern: /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/,
                        message: "邮箱格式不正确",
                        trigger: "blur"
                    }
                ],
                content: [
                    {
                        required: true,
                        message: "留言内容不能为空"
                    }
                ]
            }
        };
    },
    methods: {
        toggle(index) {
            this.currentIndex = index;
        },
        submitForm() {
            let _this = this;
            this.$refs["contactForm"].validate(val => {
                if (val) {
                    this.post(
                        "/article/index/contact",
                        _this.contactForm,
                        function (res) {
                            if (res.code == 200) {
                                _this.$message({
                                    type: "success",
                                    message: res.msg
                                });
                                _this.$refs.contactForm.resetFields();
                            } else {
                                _this.$message.error(res.msg);
                            }
                        }
                    );
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
.contact-form {
    width: 100%;
    overflow: hidden;
}
.input-wrap {
    margin-bottom: 15px;
    overflow: hidden;
    &:last-child {
        .input-group {
            em {
                opacity: 0;
            }
        }
    }
    .name {
        display: inline-block;
        float: left;
        width: 80px;
        line-height: 36px;
        font-size: 14px;
        color: #666;
    }
    .input-group {
        em {
            float: left;
            display: inline-block;
            line-height: 36px;
            margin: 0 5px;
            font-size: 12px;
            color: #fa6429;
        }
        input {
            float: left;
            border: 1px solid #d9d9d9;
            width: 260px;
            height: 36px;
            padding: 0 10px;
            font-size: 12px;
            color: #666;
            outline: none;
            &::placeholder {
                color: #ccc;
            }
        }
        textarea {
            float: left;
            border: 1px solid #d9d9d9;
            width: 400px;
            height: 180px;
            resize: none;
            outline: none;
            padding: 0 10px;
            line-height: 30px;
            font-size: 12px;
            color: #666;
            &::placeholder {
                color: #ccc;
            }
        }
    }
}
.submit {
    display: block;
    margin: 40px auto 100px auto;
    width: 120px;
    height: 36px;
    border: none;
    background: #587ddc;
    font-size: 12px;
    color: #fff;
    outline: none;
    cursor: pointer;
}
</style>




